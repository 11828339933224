import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';
import { CustomHelperProps } from 'reactour';

import Avatar from 'antd/es/avatar';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Divider from 'antd/es/divider';
import { CloseOutlined } from '@ant-design/icons';

import { useAuth } from 'src/hooks/auth';
import { useConfig } from 'src/hooks/config';

import Cleo from 'src/assets/cleo.png';

import {
  Container,
  HelperHeader,
  HelperFooter,
  HelperContent,
  HelperNavigation,
} from './styles';

interface ICustomHelper extends CustomHelperProps {
  type: 0 | 1 | 2;
  finalStep?: boolean;
  extraStep?: boolean;
}

/**
 * Componente que representa o modal de conteúdo de cada passo do Tour como forma de customizar os
 * passos da biblioteca Reactour.
 * @param current Propriedade padrão do Reactour para mostrar o passo atual do Tour
 * @param totalSteps Propriedade padrão do Reactour para mostrar o total de passos do Tour
 * @param content Propriedade padrão do Reactour que representa o conteúdo do modal de cada passo
 * @param close Propriedade padrão do Reactour que fecha o Tour
 * @param gotoStep Propriedade padrão do Reactour que muda o Tour para o próximo passo
 * @param type Indica o tipo de Tour que está sendo feito
 * @param finalStep  Indica se é o último passo do Tour da tela de Inserir NF
 * @param extraStep  Indica se é o passo extra do Tour quando o usuário fecha o Tour previamente
 */
const CustomHelper: React.FC<ICustomHelper> = ({
  current,
  totalSteps,
  content,
  close,
  gotoStep,
  type,
  finalStep = false,
  extraStep = false,
}) => {
  const { user } = useAuth();
  const { fuelInstance } = useConfig();

  const history = useHistory();

  /**
   * Componente do botão de voltar de cada passo do Tour. O botão é mostrado ou não dependendo do
   * passo em que o Tour está. No primeiro passo, não mostra o botão voltar
   */
  const BackButton = useCallback(
    ({ ...props }) => {
      return current >= 1 ? (
        <Col flex={1}>
          <Button id="back" {...props}>
            Voltar
          </Button>
        </Col>
      ) : (
        <Col span={10} />
      );
    },
    [current],
  );

  /**
   * Componente do botão de próximo de cada passo do Tour. Cada tipo e número de passo do Tour pode
   * ter uma configuração diferente. No caso do Tour pela Dashboard, o botão irá mostrar "Inserir nota fiscal"
   * no último passo do Tour e vai levar o usuário até a página de Inserir NF. No caso do Tour da
   * tela Inserir NF, irá mostrar "Inserir nota fiscal" no primeiro passo, e no último passo irá
   * mostrar "Concluir". E no caso da tela Configurações de empresa, o botão será sempre "Vamos lá",
   * mas irá direcionar o usuário até a Dashboard.
   */
  const NextButton = useCallback(
    ({ ...props }) => {
      switch (type) {
        case 0: {
          const insertFileStep = fuelInstance === 1 ? 4 : 3;

          return (
            <Col>
              {current === insertFileStep ? (
                <Button
                  id="next"
                  {...props}
                  onClick={() => {
                    history.push('/inserir-nf', {
                      openTour: true,
                      isOnTour: true,
                    });
                    close();
                  }}
                >
                  Inserir nota fiscal
                </Button>
              ) : (
                <Button
                  id="next"
                  {...props}
                  onClick={() => {
                    gotoStep(current + 1);
                  }}
                >
                  Próximo
                </Button>
              )}
            </Col>
          );
        }

        case 1:
          return (
            <Button
              id="next"
              {...props}
              onClick={() => {
                history.push('/dashboard', { openTour: true });

                if (String(process.env.REACT_APP_ENV) === 'production') {
                  mixpanel.track('Tour - Dashboard - Início', {
                    Usuario: `${user.firstName} ${user.lastName}`,
                    Empresa: user.filiacaoPrincipal?.empresa.nomeFantasia || '',
                  });
                }
              }}
            >
              Vamos lá
            </Button>
          );

        case 2:
          return finalStep || extraStep ? (
            <Button
              id="next"
              {...props}
              onClick={() => {
                close();

                if (String(process.env.REACT_APP_ENV) === 'production') {
                  mixpanel.track('Tour - Concluído', {
                    Usuario: `${user.firstName} ${user.lastName}`,
                    Empresa: user.filiacaoPrincipal?.empresa.nomeFantasia || '',
                  });
                }
              }}
            >
              Concluir
            </Button>
          ) : (
            <Col>
              <Button
                id="next"
                {...props}
                onClick={() => {
                  close();

                  if (String(process.env.REACT_APP_ENV) === 'production') {
                    mixpanel.track('Tour - Ir para Inserir Nota Fiscal', {
                      Usuario: `${user.firstName} ${user.lastName}`,
                      Empresa:
                        user.filiacaoPrincipal?.empresa.nomeFantasia || '',
                    });
                  }
                }}
              >
                Inserir nota fiscal
              </Button>
            </Col>
          );

        default:
          return <Button>Próximo</Button>;
      }
    },
    [
      close,
      current,
      extraStep,
      finalStep,
      fuelInstance,
      gotoStep,
      history,
      type,
      user.filiacaoPrincipal?.empresa.nomeFantasia,
      user.firstName,
      user.lastName,
    ],
  );

  const handleCloseTour = () => {
    history.push({ pathname: '/inserir-nf', state: { extraStep: true } });

    if (String(process.env.REACT_APP_ENV) === 'production') {
      mixpanel.track('Tour - Fechar', {
        Usuario: `${user.firstName} ${user.lastName}`,
        Empresa: user.filiacaoPrincipal?.empresa.nomeFantasia || '',
        'Passo atual': current + 1,
      });
    }

    close();
  };

  return (
    <Container>
      <HelperHeader>
        <div>
          <Avatar size={60} style={{ marginRight: 12 }} src={Cleo} />
          Cléo da Combudata
        </div>
        <CloseOutlined onClick={handleCloseTour} />
      </HelperHeader>
      <HelperContent>{content}</HelperContent>
      <Divider />
      <HelperFooter>
        <Row justify="end" align="middle">
          <BackButton
            type="primary"
            ghost
            onClick={() => gotoStep(current - 1)}
          />

          {type === 0 && (
            <Col flex={1}>
              <HelperNavigation>
                {`${current + 1} de ${totalSteps}`}
              </HelperNavigation>
            </Col>
          )}
          <NextButton type="primary" />
        </Row>
      </HelperFooter>
    </Container>
  );
};

export default CustomHelper;
